import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Collapsible from "react-collapsible"
import parse from "html-react-parser"
import { OutboundLink } from "gatsby-plugin-google-gtag"

export default function FrequentlyAskedQuestions({ data }) {
  return (
    <Layout>
      <Seo
        title="Frequently Asked Questions"
        description="Answers to common questions about the Awards."
      />



      <section id="faq-page" className="page-pad">
        <div className="page">


    
        {/* <section className="faq-alert">
          <h3>Awards Update:</h3>
          <p>
          On December 18, the Alliance for Young Artists & Writers announced that deadlines for regional awards
          <br /> are being extended up through <strong>February 29 2024</strong>, depending on the region. 
          </p>
          <p>
          <OutboundLink href="https://www.artandwriting.org/regions/">
          An up-to-date list of regional deadlines can be found on our website.</OutboundLink>
          </p>      
          <p>
            If your deadline falls between <strong>January 15-February 29</strong>, <br />we encourage you to <strong>hold off on accessing the portal</strong> until the new year.
          </p>
        </section> */}


          <div className="faq-column">
            <h2>Frequently Asked Questions</h2>
            <section className="faq">
              <ul className="faq-anchors">
                {data.FaqCategories.edges.map(category => (
                  <li className="faq-anchor-item" key={category.node.name}>
                    <a href={`#${category.node.slug}`}>{category.node.name}</a>
                  </li>
                ))}
              </ul>
            </section>

            <section>
              {data.FaqCategories.edges.map(category => (
                <div className="faq-group" key={category.node.id}>
                  <a
                    href={`./#${category.node.slug}`}
                    className="faq-anchor"
                    name={`${category.node.slug}`}
                  >
                    <h3> {category.node.name} </h3>
                  </a>

                  {category.node.name === "Getting Started" ||
                  category.node.name === "Work Upload" ? (
                    <div style={{ marginBottom: "30px" }}>
                      <i>
                      For guidance on all steps of the Scholastic Awards entry
                      process, view our walkthrough series for{" "}
                      <OutboundLink href="https://www.youtube.com/playlist?list=PLirxWhcIrkT6V63Rx1OMDsgdFzvxViRv3">
                        Teens
                      </OutboundLink>{" "}
                      and{" "}
                      <OutboundLink href="https://www.youtube.com/playlist?list=PLirxWhcIrkT5vHvKBEiMoDKZRb7umAI0i">
                        Educators.
                      </OutboundLink>
                      </i>
                    </div>
                  ) : null}

                  {category.node.faqs.nodes.map((faq, index) => (
                    <div className="faq-single" key={index}>
                      <Collapsible
                        transitionTime={250}
                        transitionCloseTime={220}
                        easing={"linear"}
                        className="faq-title"
                        triggerOpenedClassName="faq-title-open"
                        triggerClosedClassName="faq-title"
                        trigger={faq.title}
                        contentInnerClassName="faq-content"
                        contentOuterClassName="faq-content"
                      >
                        <span className="text">{parse(faq.content)}
                  </span>
                      </Collapsible>

                    </div>
                  ))}
                </div>
              ))}
            </section>
          </div>
        </div>
      </section>
      {/* </div> */}
    </Layout>
  )
}

export const query = graphql`
  query FaqQuery {
    FaqCategories: allWpFaqCategory(sort: { fields: id, order: ASC }) {
      edges {
        node {
          id
          name
          slug
          description
          faqs {
            nodes {
              title
              content
            }
          }
        }
      }
    }
  }
`
